import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CheckboxGroup } from '@shared/models';

/**
 * Check if a FormGroup status is valid
 *
 * @param formGroup FormGroup
 * @returns True is valid, or False if invalid
 */
export const isFormValid = (formGroup: FormGroup): boolean => {
  const isValid = formGroup.valid;

  if (!isValid) {
    Object.values(formGroup.controls).forEach(control => {
      const childControls = control as FormGroup;
      if (childControls.controls) {
        isFormValid(childControls);
      }

      isControlValid(control as FormControl);
    });
  }

  return isValid;
};

export const isControlValid = (control: FormControl): boolean => {
  const isValid = control.valid;

  if (!isValid) {
    control.markAsDirty();
    control.updateValueAndValidity();
  }

  return isValid;
};

/**
 * Check if the confirm password and new password are equal
 *
 * @param passwordControlName String with the control name
 * @returns ValidationErrors or Null
 */
export const validateConfirmPassword = (
  passwordControlName: string
): ValidatorFn => {
  return (control: FormControl) => {
    if (!control?.parent) {
      return null;
    }

    return control.parent.get(passwordControlName).value === control.value
      ? null
      : { mismatch: true };
  };
};

/**
 * Custom validator function that validates disabled controls
 *
 * @returns ValidationErrors or Null
 */
export const validateDisabledControl = (formGroup: FormGroup): ValidatorFn => {
  Object.values(formGroup.controls).forEach(control => {
    if (
      control.disabled &&
      control.hasValidator(Validators.required) &&
      !control.value
    ) {
      control.markAsDirty();
      control.updateValueAndValidity({ onlySelf: true });
    }

    const childControls = control as FormGroup;
    if (childControls.controls) {
      isFormValid(childControls);
    }
  });

  return null;
};

/**
 * Validates that at least one checkbox is selected in a checkbox group.
 * @returns A validator function that returns an error if no checkboxes are selected, otherwise null.
 */
export const checkboxAtLeastOneSelected = (): ValidatorFn => {
  return (control: FormControl) => {
    if (!control?.value) {
      return null;
    }

    const checked = (control.value as CheckboxGroup[]).some(
      checkbox => checkbox.checked
    );

    return checked ? null : { invalidCheckboxes: true };
  };
};
